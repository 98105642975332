import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function MilfordSoundTourMtCook(props) {
  return (
    <>
      <SEO title="Milford Sound + Christchurch to Queenstown Bus - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/mount-cook-mountain-flower.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/coach.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/mount-cook-mountain-divide.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            {/* FIXME: Custom booking form required */}
            <BookingForm tourCode="TEHAMO" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Milford Sound Tour + Mt Cook Tour Combo</h1>
                <h3>
                  Experience the beauty of Milford Sound and the wonder of NZ's
                  tallest mountain
                </h3>
                <div class="highlights">
                  <ul>
                    <li>Two-day tour combo</li>
                    <li>Milford Sound day tour &gt; cruise</li>
                    <li>Mount Cook day tour (Christchurch to Queenstown)</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      From NZ's tallest mountain to its most-visited attraction,
                      this combo has you covered.
                    </p>
                    <p>
                      Take in two of New Zealand's most famous natural
                      attractions with this two-day tour combo: Aoraki / Mount
                      Cook, its tallest mountain, and the unspoilt beauty of{" "}
                      <strong>Milford Sound</strong>.
                    </p>
                    <h4>Day 1</h4>
                    <p>
                      Your <strong>Mount Cook day tour</strong> starts in{" "}
                      <strong>Christchurch</strong> and finishes in{" "}
                      <strong>Queenstown</strong>, passing through landscapes of
                      fertile pasture, glacier-fed lakes, and soaring
                      snow-capped mountains. Along the way you'll see{" "}
                      <strong>Lake Tekapo</strong> with the picturesque{" "}
                      <strong>Church of the Good Shepherd</strong> on its
                      shores, and <strong>Lake Pukaki</strong> with&nbsp;its
                      vivid turquoise waters.
                    </p>
                    <p>
                      Venture into{" "}
                      <strong>Aoraki/Mount Cook National Park</strong>, home to
                      700km<sup>2</sup> of mountains, glaciers and native plants
                      and wildlife. Climb high into the Southern Alps to Mount
                      Cook Village, where you'll have stunning views of the
                      biggest peak of them all - Aoraki / Mount Cook, standing
                      3,724m high.
                    </p>
                    <p>
                      Grab some lunch (own expense) and explore the gorgeous
                      alpine community nestled at the foot of the mighty
                      snow-capped mountain. You'll also have the opportunity to
                      view this special part of the world from on high with a
                      scenic helicopter or ski plane flight. This an additional
                      cost and weather permitting - check with your driver on
                      the day.
                    </p>
                    <p>
                      Leaving Aoraki / Mount Cook, continue south through the
                      tawny tussock of the <strong>Mackenzie Country</strong>,
                      stopping for afternoon tea (own expense) in the quaint
                      village of <strong>Omarama</strong> and a visit to the
                      Jones Family Orchard (time permitting) near{" "}
                      <strong>Cromwell</strong> in Central Otago before arriving
                      at your destination - Queenstown, home of bungy jumping
                      and the starting point for your journey into{" "}
                      <strong>Milford Sound</strong>.
                    </p>
                    <h4>DAY 2</h4>
                    <p>
                      Your Milford Sound day tour starts as you travel south
                      along Lake Wakatipu, passing through rugged Southland
                      farmland to Lake Te Anau. Stop for a bite to eat before
                      entering world famous{" "}
                      <strong>Fiordland National Park</strong>. Pass through the{" "}
                      <strong>Eglinton Valley</strong>, home to ancient alpine
                      forests, massive waterfalls and the famous{" "}
                      <strong>Mirror Lakes</strong>. Cross under the mountains
                      through the 1.2km long <strong>Homer Tunnel</strong> (hewn
                      from solid rock) and descend into{" "}
                      <strong>Milford Sound,</strong> where you'll board your
                      cruise and enjoy a&nbsp;<strong>FREE lunch</strong>.<br />
                      <br />
                      Cruise along the fiord and take in the famous sights and
                      landmarks of Milford Sound, like the uniquely-shaped{" "}
                      <strong>Mitre Peak</strong>, glacier-scarred rock walls
                      that rise from the sea. Keep an eye out for marine life
                      like dolphins, seals and penguins during your cruise, and
                      get up close to the waterfalls that tumble through the
                      valleys and crash into the sound. Following the cruise
                      your guide will be waiting to drive you back to
                      Queenstown.
                    </p>
                    <h3 class="label">
                      Booking your Milford Sound + MT COOK tour Combo
                    </h3>
                    <p>
                      When you purchase this tour combo, you're pre-paying for a
                      pass which gives you access to these two tours at a
                      discounted rate. You can then use the pass to book each
                      tour on your chosen travel dates.{" "}
                      <strong>
                        Buying the pass does not automatically book you onto the
                        tours.
                      </strong>
                    </p>
                    <p>
                      At the checkout you'll be asked to choose an access code
                      (a bit like a password - it can be anything you like).
                      We'll send you a confirmation email with your pass number.
                    </p>
                    <p>
                      You can then{" "}
                      <Link title="Contact awesomeNZ" to="/contact-us">
                        contact us
                      </Link>{" "}
                      by phone or email to make your booking, or book the tours
                      online by visiting our partner website, InterCity. To book
                      online,{" "}
                      <a
                        title="Book your tour combo"
                        href="https://www.intercity.co.nz/passdetail"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        log in to the InterCity website
                      </a>{" "}
                      using your pass number and access code, then follow the
                      below instructions.&nbsp;
                    </p>
                    <p>
                      <strong>
                        To book your Milford Sound day tour and cruise:
                      </strong>
                    </p>
                    <ul>
                      <li>
                        Search for one-way travel from Queenstown to Queenstown
                        on your preferred travel date.
                      </li>
                      <li>
                        Click on the search results for full details and select
                        the Milford Sound day tour.
                      </li>
                      <li>
                        You'll see a link marked 'Optional extras are available
                        - please select any you wish to add'. Expand this and
                        select the Miford Sound cruise to add it to your
                        booking.&nbsp;
                        <strong>
                          Booking the coach portion of the tour does not
                          automatically book the cruise - you must add the
                          cruise to your cart as well.
                        </strong>
                      </li>
                      <li>
                        Once you've added the tour and cruise to your cart,
                        follow the rest of the steps to the checkout to confirm
                        your booking.
                      </li>
                    </ul>
                    <p>
                      <strong>
                        To book your Christchurch - Mt Cook - Queenstown day
                        tour:
                      </strong>
                    </p>
                    <ul>
                      <li>
                        Search for one-way travel from Christchurch to
                        Queenstown on your preferred travel date. (You must
                        choose a different date from your Milford Sound tour and
                        cruise.)
                      </li>
                      <li>
                        Select the available service and add it to your cart,
                        then follow the rest of the steps to the checkout to
                        confirm your booking.
                      </li>
                    </ul>
                    <p>
                      Once you have booked the tours you will receive a
                      confirmation email showing the dates and times of your
                      tours, along with other useful information. If you have
                      not received a confirmation email please{" "}
                      <Link title="Contact awesomeNZ" to="/contact-us">
                        contact us
                      </Link>
                      .
                    </p>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                {/* ... */}
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-christchurch-queenstown">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/milford_chc_zqn.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound + Christchurch - Queenstown travel</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$199.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-christchurch-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MilfordSoundTourMtCook
